import { initWebviewLayout, observabilityInit } from '@banksalad/js-banksalad';
import ReactDOM from 'react-dom';

import { SCREEN_STATEMENT } from '@/trackers';
import { NAMESPACE_ENV } from '@/utils/extension';
import { initSentry } from '@/utils/sentry';
import { EntryRoute } from '@/views';

observabilityInit();
initSentry();
initWebviewLayout();
window.observability.set('screenStatement', SCREEN_STATEMENT);

if (window.namespaceEnv !== NAMESPACE_ENV.PRODUCTION) {
  import('@/utils/v-console').then(initVConsole => initVConsole.default());
}

ReactDOM.render(<EntryRoute />, document.getElementById('wrap'));

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
import * as Sentry from '@sentry/browser';
import React, { Component, ErrorInfo, ReactNode } from 'react';

import { FetchError } from '@/components/fetch-error';

interface Props {
  children: ReactNode;
}

interface State {
  error: Error;
  errorInfo: ErrorInfo;
}

export class ErrorBoundary extends Component<Props, State> {
  state = {
    error: null as unknown as Error,
    errorInfo: null as unknown as ErrorInfo,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error, errorInfo });
    Sentry.withScope(scope => {
      scope.setExtras({
        ...errorInfo,
      });
    });

    Sentry.captureException(error);
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <FetchError
          onRetry={() => {
            window.location.reload();
          }}
        />
      );
    }

    return this.props.children;
  }
}

import { useQuery } from 'react-query';

import { getInvestmentRevenues } from '@/api/investment-revenues';
import { InvestmentRevenueEntity } from '@/entities/revenue';

export const useFetchInvestmentRevenues = () => {
  const {
    data: investmentRevenueData,
    error,
    isLoading,
    isError,
    ...rest
  } = useQuery('investment-revenues', () => getInvestmentRevenues());

  if (isError) {
    throw error;
  }

  if (isLoading) {
    return {
      ...rest,
      isLoading,
      isError,
      data: {} as InvestmentRevenueEntity,
    };
  }

  return {
    ...rest,
    isLoading,
    isError,
    data: investmentRevenueData,
  };
};

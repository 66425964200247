/* eslint-disable no-nested-ternary */
import {
  DividerHeight1Margin20,
  ListItemSmall1,
  ListMargin0,
  SpacingHeight8,
  SpacingHeight12,
  SpacingHeight16,
  SpacingHeight20,
  TitleChildLeftTextIconLarge1,
  TitleChildLeftTextLarge1,
  TitleChildLeftTextMedium1,
  TitleChildRightTextMedium1,
  TitleXLarge1,
} from '@banksalad/bpl-web';

import { InvestmentRevenueEntity } from '@/entities/revenue';
import { toCurrencyWithFloor } from '@/utils';
import { isProfit } from '@/utils/icon-renderer';
import { refineInvestmentRevenue } from '@/views/dashboard-exp/controller';

interface Props {
  investmentRevenue: InvestmentRevenueEntity;
}

export const InvestmentSummary = ({ investmentRevenue }: Props) => {
  const { principal, totalRevenue, totalRevenueRate } = refineInvestmentRevenue(investmentRevenue);

  const renderTotalRevenue = (totalRevenue: number, totalRevenueRate: number | string) => {
    const profit = isProfit(totalRevenue);
    const rateText = totalRevenue !== 0 ? ` (${totalRevenueRate}%)` : '';
    const prefix = totalRevenue > 0 ? '+' : '';
    return (
      <TitleChildRightTextMedium1
        style={{
          maxWidth: 'none',
          textAlign: 'end',
          flexShrink: 1,
        }}
        text={`${prefix}${toCurrencyWithFloor(totalRevenue)}원${rateText}`}
        color={profit ? 'red-100' : totalRevenue === 0 ? 'gray-050' : 'blue-100'}
        fontWeight="medium"
      />
    );
  };

  return (
    <>
      <SpacingHeight20 />
      <TitleXLarge1>
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <TitleChildLeftTextIconLarge1 text="요약" fontWeight="bold" icon={<></>} />
      </TitleXLarge1>
      <SpacingHeight16 />
      <ListMargin0>
        <ListItemSmall1>
          <TitleChildLeftTextMedium1 text="투자원금" color="gray-500" fontWeight="regular" />
          <TitleChildRightTextMedium1
            color="gray-050"
            fontWeight="medium"
            text={`${toCurrencyWithFloor(principal)}원`}
          />
        </ListItemSmall1>
        <SpacingHeight8 />
        <DividerHeight1Margin20 />
        <SpacingHeight12 />
        <ListItemSmall1>
          <TitleChildLeftTextMedium1
            style={{ flexShrink: 0 }}
            text="총 수익금(률)"
            color="gray-500"
            fontWeight="regular"
          />
          {renderTotalRevenue(totalRevenue, totalRevenueRate)}
        </ListItemSmall1>
        <SpacingHeight8 />
      </ListMargin0>
    </>
  );
};

export const EmptyInvestmentSummary = () => (
  <>
    <SpacingHeight20 />
    <TitleXLarge1>
      <TitleChildLeftTextLarge1 text="요약" fontWeight="bold" />
    </TitleXLarge1>
    <SpacingHeight16 />
    <ListMargin0>
      <ListItemSmall1>
        <TitleChildLeftTextMedium1 text="투자원금" color="gray-500" fontWeight="regular" />
        <TitleChildRightTextMedium1 color="gray-050" fontWeight="medium" text="0원" />
      </ListItemSmall1>
      <SpacingHeight8 />
      <DividerHeight1Margin20 />
      <SpacingHeight12 />
      <ListItemSmall1>
        <TitleChildLeftTextMedium1 text="총 수익금(률)" color="gray-500" fontWeight="regular" />
        <TitleChildRightTextMedium1 color="gray-050" fontWeight="medium" text="0원" />
      </ListItemSmall1>
    </ListMargin0>
  </>
);

import '@/styles/util.pcss';

import { DialogProvider, ModalProvider } from '@banksalad/bpl-web';
import {
  ErrorViewProps,
  useOnAuthorizationCookieUpdated,
  withAppInfo,
} from '@banksalad/js-banksalad';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Switch } from 'react-router-dom';

import { ErrorBoundary } from '@/components/error-boundary';
import { FetchError } from '@/components/fetch-error';
import { Loader } from '@/components/loader';
import { Root } from '@/components/root';
import { ROUTE } from '@/utils/link';
import { DashboardExp } from '@/views/dashboard-exp';
import { DashboardSubHomeFund } from '@/views/dashboard-sub-home/fund';
import { DashboardSubHomeStock } from '@/views/dashboard-sub-home/stock';

require('@banksalad/bpl-web/lib/dist/src/slider.css');

const queryClient = new QueryClient();
const App = () => {
  useOnAuthorizationCookieUpdated(
    () => {
      window.location.reload();
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => {}
  );

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Root>
          <DialogProvider duration={200} positionY={0}>
            <ModalProvider>
              <Switch>
                <Route path={`${ROUTE.DASHBOARD}`} component={DashboardExp} />
                <Route
                  path={`${ROUTE.DASHBOARD_SUB_HOME}/stock`}
                  component={DashboardSubHomeStock}
                />
                <Route path={`${ROUTE.DASHBOARD_SUB_HOME}/fund`} component={DashboardSubHomeFund} />
              </Switch>
            </ModalProvider>
          </DialogProvider>
        </Root>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

const ErrorView: React.FC<ErrorViewProps> = ({ retryAppInfoLoading }) => (
  <FetchError onRetry={retryAppInfoLoading} />
);

export const EntryRoute = withAppInfo(App, ErrorView, Loader);

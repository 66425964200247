import { getWebViewDeepLink, openFullScreenWebView } from '@banksalad/js-banksalad';

import { isIOS, isProd } from '@/utils/extension';
import { ASSET_TYPES } from '@/utils/portfolio-data-format';

export const ROUTE = {
  INVESTMENT_DETAIL: '/investment-detail',
  ACCOUNT_DETAIL: '/account-detail',
  DASHBOARD: '/dashboard',
  DASHBOARD_SUB_HOME: '/dashboard-sub-home',
};

export const WEBVIEW_BASE_URL = isProd()
  ? 'https://investment.wv.banksalad.com/v2'
  : 'https://investment.wv.staging.banksalad.com/v2';

export const setLocation = (URL: string) => {
  window.location.href = URL;
};

export const linkTo = {
  dashboardSubHome: (assetType: ASSET_TYPES) => {
    const getPathByAssetType = () => {
      switch (assetType) {
        case ASSET_TYPES.STOCK:
          return '/stock';
        case ASSET_TYPES.FUND:
          return '/fund';
        default:
          return '/';
      }
    };

    return setLocation(
      getWebViewDeepLink({
        url: `${WEBVIEW_BASE_URL}${ROUTE.DASHBOARD_SUB_HOME}${getPathByAssetType()}`,
        title: '',
      })
    );
  },
};

export const linkToConnect = (industryHash: PrimaryConnectableIndustry) => {
  const queryParams = `industryHash=${industryHash}`;
  const url = isProd()
    ? `https://webview.banksalad.com/useronboarding/connect?${queryParams}`
    : `https://webview.staging.banksalad.com/useronboarding/connect?${queryParams}`;

  if (isIOS()) {
    setLocation(`banksalad://me/mydata-connect?url=${encodeURIComponent(url)}`);
  } else {
    openFullScreenWebView({
      url,
    });
  }
};

export type PrimaryConnectableIndustry =
  | 'INDUSTRY_BANK' // 은행 - 은행(1 금융권), 제2 금융권(저축은행, 협동조합)
  | 'INDUSTRY_ELECTRONIC_FINANCE' // 페이머니
  | 'INDUSTRY_CARD' // 카드
  | 'INDUSTRY_INVEST' // 증권
  | 'INDUSTRY_INSURANCE' // 보험 - 손해보험, 생명보험
  | 'INDUSTRY_CASH_RECEIPTS' // 현금영수증(마이데이터 비대상)
  | 'INDUSTRY_HEALTH' // 건강(마이데이터 비대상)
  | 'INDUSTRY_CREDIT_MANAGEMENT' // 신용관리(마이데이터 비대상)
  | 'INDUSTRY_TELECOM' // 통신
  | 'INDUSTRY_REAL_ESTATE' // 부동산(마이데이터 비대상)
  | 'INDUSTRY_CAR' // 자동차(마이데이터 비대상)
  | 'INDUSTRY_ANNUITY' // 연금(마이데이터 비대상)
  | 'INDUSTRY_USER_CONFIGURATION' // 수기입력(마이데이터 비대상)
  | 'INDUSTRY_CAPITAL' // 할부금융
  | 'INDUSTRY_UNKNOWN';

import {
  BdsColorIconInvestmentCoinLarge1Svg,
  BdsColorIconInvestmentDepositLarge1Svg,
  BdsColorIconInvestmentFundLarge1Svg,
  BdsColorIconInvestmentStockLarge1Svg,
  Color,
} from '@banksalad/bpl-web';

import { ChartData } from '@/entities/chart-data';

export const PERCENTAGE_DECIMAL_POSITION = 1;

export enum ASSET_TYPES {
  STOCK = 'stock',
  FUND = 'fund',
  COIN = 'coin',
}

interface ColorSet {
  [ASSET_TYPES.STOCK]: Color;
  [ASSET_TYPES.FUND]: Color;
  [ASSET_TYPES.COIN]: Color;
}

export const ASSET_COLOR: ColorSet = {
  [ASSET_TYPES.STOCK]: 'light-blue-100',
  [ASSET_TYPES.FUND]: 'mint-100',
  [ASSET_TYPES.COIN]: 'mustard-100',
} as const;

export const ASSET_TYPE_KOR = {
  [ASSET_TYPES.STOCK]: '주식',
  [ASSET_TYPES.FUND]: '펀드',
  [ASSET_TYPES.COIN]: '코인',
} as const;

export const getChartDataWithColor = (assetType: ASSET_TYPES, value: number): ChartData => ({
  title: ASSET_TYPE_KOR[assetType],
  value,
  color: ASSET_COLOR[assetType],
  tooltipColor: ASSET_COLOR[assetType],
});

export const getIconByAssetType = (assetType: ASSET_TYPES) => {
  switch (assetType) {
    case ASSET_TYPES.STOCK:
      return BdsColorIconInvestmentStockLarge1Svg;
    case ASSET_TYPES.FUND:
      return BdsColorIconInvestmentFundLarge1Svg;
    case ASSET_TYPES.COIN:
      return BdsColorIconInvestmentCoinLarge1Svg;
    default:
      return BdsColorIconInvestmentDepositLarge1Svg;
  }
};

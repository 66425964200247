import { BrowserRouter } from 'react-router-dom';

import s from './styles.pcss';

const disableBottomSafeAreaPathRegex = [/^\/v2\/dashboard\/?$/ /* = /v2/dashboard */];

export const Root = ({ children }) => {
  const disableBottomSafeArea = disableBottomSafeAreaPathRegex.some(regex =>
    regex.test(window.location.pathname)
  );

  return (
    <BrowserRouter basename="/v2">
      <section className={disableBottomSafeArea ? s.safeTop : s.safe}>{children}</section>
    </BrowserRouter>
  );
};

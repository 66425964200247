import { BdsMonoCommonLossXSmall1Svg, BdsMonoCommonProfitXSmall1Svg } from '@banksalad/bpl-web';

export const getRate = (principal: number, valuation: number): string => {
  if (principal === 0) {
    return '0.00';
  }

  const revenue = valuation - principal;
  return ((revenue / principal) * 100).toFixed(2);
};

export const getProfitColor = (revenue: number) => {
  if (revenue > 0) {
    return 'red-100';
  }
  if (revenue < 0) {
    return 'blue-100';
  }
  return 'gray-500';
};

export const getProfitIcon = (revenue: number) => {
  const color = getProfitColor(revenue);
  if (revenue === 0) {
    return undefined;
  }
  return revenue > 0 ? (
    <BdsMonoCommonProfitXSmall1Svg width={8} height={8} color={color} />
  ) : (
    <BdsMonoCommonLossXSmall1Svg width={8} height={8} color={color} />
  );
};

import { AppInfo, ApplicationName } from './shared.type';

export const OS_NAME = {
  WEB: 'web',
  ANDROID: 'android',
  IOS: 'ios',
} as const;

export const APPLICATION_NAME = {
  WEB: 'banksalad2:Web',
  ANDROID: 'banksalad2:Android',
  IOS: 'banksalad2:iOS',
} as const;

/**
 * application name을 os name으로 변환합니다.
 */
export const getOsName = (name: string): AppInfo['osName'] | never => {
  /* cookie 에서 값을 조회할 때 ':' 이 '%3A'로 encoding 되는 경우가 있어서 decode해서 사용한다.  */
  switch (decodeURIComponent(name) as ApplicationName) {
    case APPLICATION_NAME.IOS:
      return OS_NAME.IOS;
    case APPLICATION_NAME.ANDROID:
      return OS_NAME.ANDROID;
    case APPLICATION_NAME.WEB:
      return OS_NAME.WEB;
    // 일부 서비스 환경에서 banksalad-application-name 을 ios/android 로 세팅하는 경우가 있기 때문에 그 경우를 대비해 추가함
    case 'ios':
      return OS_NAME.IOS;
    case 'android':
      return OS_NAME.ANDROID;
    default:
      throw new Error(`invalid application name. (${name} 호출됨)`);
  }
};

import { DividerHeight12Margin0, SpacingHeight20, SpacingHeight24 } from '@banksalad/bpl-web';
import React, { useEffect } from 'react';

import { FetchError } from '@/components/fetch-error';
import { Loader } from '@/components/loader';
import { useFetchInvestmentRevenues, useFetchStockInvestments } from '@/hooks/fetch';
import { trackExposeViewSubHomeStock } from '@/trackers/dashboard-exp';
import { DashboardSubHomeStockList } from '@/views/dashboard-sub-home/stock/list';
import { DashboardSubHomeStockSummary } from '@/views/dashboard-sub-home/stock/summary';

export const DashboardSubHomeStock = () => {
  const {
    data: _investmentRevenueData,
    isLoading: revenueLoading,
    isError: revenueError,
  } = useFetchInvestmentRevenues();
  const {
    data: stocksData,
    isLoading: stockLoading,
    isError: stockError,
  } = useFetchStockInvestments();

  useEffect(() => {
    trackExposeViewSubHomeStock();
  }, []);

  if (revenueLoading || stockLoading) {
    return <Loader />;
  }
  if (revenueError || stockError) {
    return <FetchError onRetry={window.location.reload} />;
  }

  const investmentRevenueData = _investmentRevenueData!;
  const stockRevenue = investmentRevenueData.stock_revenue;
  const stockList = stocksData.data;

  return (
    <>
      <DashboardSubHomeStockSummary stockRevenue={stockRevenue} />

      <SpacingHeight24 />
      <DividerHeight12Margin0 />
      <SpacingHeight20 />

      <DashboardSubHomeStockList stockList={stockList} />
    </>
  );
};

import {
  BadgeMedium,
  BdsColorLogoPlaceholderStockSvg,
  ListItemChildLeftIconTextSmall5,
  ListItemChildRightLine2Medium2,
  ListItemMedium1,
} from '@banksalad/bpl-web';

import { FundEntity } from '@/entities/investment/fund';
import { toCurrencyWithFloor } from '@/utils';
import { getProfitColor, getProfitIcon } from '@/views/dashboard-sub-home/controller';

import s from './styles.pcss';

interface Props {
  fundList: FundEntity[];
}
export const DashboardSubHomeFundList = ({ fundList }: Props) => (
  <>
    {fundList.map((fund, idx) => {
      const {
        fund_display_name,
        company,
        principal: principal_amount,
        valuation: valuation_amount,
        is_manually_created,
        revenue_rate_2f,
      } = fund;
      const securitiesFirmImage = company.image_url_v2;
      const principal = principal_amount?.amount ?? 0;
      const valuation = valuation_amount?.amount ?? principal;
      const revenue = valuation - principal;
      const revenueAbs = Math.abs(valuation - principal);
      const revenueRate = revenue_rate_2f / 100;

      return (
        <ListItemMedium1 key={`${fund.id}-${idx}`}>
          <ListItemChildLeftIconTextSmall5
            text={fund_display_name}
            image={
              securitiesFirmImage ? (
                <img className={s.logo} src={securitiesFirmImage} alt={`${company.name} 로고`} />
              ) : (
                <BdsColorLogoPlaceholderStockSvg width={34} height={34} />
              )
            }
            badge={
              is_manually_created ? (
                <BadgeMedium
                  text="직접"
                  color="gray-500"
                  backgroundColor="gray-500"
                  backgroundOpacity={15}
                />
              ) : undefined
            }
          />
          <ListItemChildRightLine2Medium2
            text={`${toCurrencyWithFloor(valuation)}원`}
            subtext={`${toCurrencyWithFloor(revenueAbs)}원(${revenueRate}%)`}
            subtextColor={getProfitColor(revenue)}
            subtextIcon={getProfitIcon(revenue)}
          />
        </ListItemMedium1>
      );
    })}
  </>
);

import { ScreenName, sendTrackEvent, TrackEventName } from '@/trackers';

export const trackExposeViewInvestDashboardExp = () => {
  sendTrackEvent(TrackEventName.EXPOSE_VIEW, ScreenName.INVEST_DASHBOARD);
};

export const trackExposeViewAssetInvestDashboardExp = () => {
  sendTrackEvent(TrackEventName.EXPOSE_VIEW, ScreenName.ASSET_INVEST_DASHBOARD);
};

export const trackExposeViewSubHomeStock = () => {
  sendTrackEvent(TrackEventName.EXPOSE_VIEW, ScreenName.INVEST_DASHBOARD_STOCK_DETAIL);
};

export const trackExposeViewSubHomeFund = () => {
  sendTrackEvent(TrackEventName.EXPOSE_VIEW, ScreenName.INVEST_DASHBOARD_FUND_DETAIL);
};

export const trackClickInvestDashboardConnect = () => {
  sendTrackEvent(TrackEventName.CLICK, ScreenName.INVEST_DASHBOARD_CONNECT);
};

export const trackClickAssetInvestDashboardConnect = () => {
  sendTrackEvent(TrackEventName.CLICK, ScreenName.ASSET_INVEST_DASHBOARD_CONNECT);
};

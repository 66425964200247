import {
  BdsGraphic,
  ButtonContainerNormalFlexible,
  ButtonNormalSmall,
  EmptyViewNormal,
  SpacingHeight12,
} from '@banksalad/bpl-web';
import { css } from '@emotion/react';
import { parse } from 'query-string';
import React, { lazy, Suspense } from 'react';

import { ChartData } from '@/entities/chart-data';
import {
  trackClickAssetInvestDashboardConnect,
  trackClickInvestDashboardConnect,
} from '@/trackers/dashboard-exp';
import { linkToConnect } from '@/utils/link';
import s from '@/views/dashboard-exp/styles.pcss';

const DonutChart = lazy(() => import(/* webpackChunkName: "chart" */ '@/components/chart-title'));

interface PieChartProps {
  chartData: ChartData[];
}

export const PieChart = ({ chartData }: PieChartProps) => (
  <Suspense fallback={<div className={s.chartWrap} />}>
    <DonutChart data={chartData} />
  </Suspense>
);

export const EmptyPieChart = () => {
  const query = parse(window.location.search);
  const referer = query && query.referer;
  const trackClickEvent =
    referer === 'asset' ? trackClickAssetInvestDashboardConnect : trackClickInvestDashboardConnect;

  const handleClick = () => {
    trackClickEvent();
    linkToConnect('INDUSTRY_INVEST');
  };

  return (
    <EmptyViewNormal
      title="연결된 자산이 없어요"
      image={
        <>
          <BdsGraphic
            css={css`
              width: 375px;
              height: 138px;
            `}
            resourceId="https://cdn.banksalad.com/graphic/color/illustration/empty/graph-pie-large.png"
          />
          <SpacingHeight12 />
        </>
      }
      text={`증권사 연결하고\n나만의 금융 포트폴리오를 확인하세요!`}
    >
      <ButtonContainerNormalFlexible>
        <ButtonNormalSmall
          text="추가하기"
          color="light-blue-100"
          backgroundColor="light-blue-100"
          backgroundOpacity={15}
          styleType="flexible"
          onClick={handleClick}
        />
      </ButtonContainerNormalFlexible>
    </EmptyViewNormal>
  );
};

import { useQuery } from 'react-query';

import { getFundInvestments } from '@/api/investment';
import { FundsEntity } from '@/entities/investment/fund';

export const useFetchFundInvestments = () => {
  const {
    data: fundData,
    error,
    isLoading,
    isError,
    ...rest
  } = useQuery('funds-investment', getFundInvestments);

  if (isError) {
    throw error;
  }

  if (isLoading) {
    return {
      ...rest,
      isLoading,
      isError,
      data: {} as FundsEntity,
    };
  }

  const filteredFundData = fundData!.data.filter(
    d => !(d.hidden || d.closed) && d.deleted_status === 'normal'
  );

  return {
    ...rest,
    isLoading,
    isError,
    data: {
      ...fundData,
      data: filteredFundData,
    } as FundsEntity,
  };
};

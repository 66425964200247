import { toCurrency } from '@banksalad/js-banksalad';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

/**
 * @param value
 * @param locales
 * @description
 * js-banksalad 의 toCurrency 함수가 소수점을 반올림 하기 때문에 소수점을 버림하고자 할때 사용합니다.
 * 투자 웹 화면에서 원화표기할 때 소수점을 버림합니다.
 * https://banksalad.slack.com/archives/C014VHKQ76U/p1646710714569599?thread_ts=1646353097.129939&cid=C014VHKQ76U
 */
export const toCurrencyWithFloor = (value: number, locales: string | string[] = 'ko-KR') =>
  toCurrency(Math.floor(value), 0, locales);

dayjs.extend(utc);
export const parseDate = (date: string, toUTC = false): Dayjs =>
  toUTC ? dayjs.utc(date) : dayjs(date);

const PIE_CHART_MAX_LEN = 6;

export const sliceArray = <T>(arr: T[], maxLen = PIE_CHART_MAX_LEN): T[] => arr.slice(0, maxLen);

export const isInt = (n: number) => n % 1 === 0;

export const omitAfterDecimalPoint = (decimal: number, position: number) => {
  const decimalPosition = isInt(decimal) ? 0 : position;
  const fixedDecimal = decimal.toFixed(decimalPosition);
  const regexResult = fixedDecimal.match(/(?<integer>\d+)\.[0]+/);
  if (regexResult) {
    return regexResult.groups!.integer;
  }
  return fixedDecimal;
};

import { EventPlatform, trackAnalyticsEvent } from '@banksalad/js-banksalad';
import * as Sentry from '@sentry/browser';

export interface TrackEventParam {
  object_type?: string;
  object_category?: string;
  object_id?: string;
  object_name?: string;
  object_organization?: string;
  object_organization_type?: string;
  option1?: string;
  option2?: string;
  option3?: string;
  option4?: string;
}

export enum TrackEventName {
  CLICK = 'click',
  EXPOSE_VIEW = 'expose_view',
}

export enum ScreenName {
  INVEST_DASHBOARD = 'invest_dashboard',
  ASSET_INVEST_DASHBOARD = 'asset_invest_dashboard',

  INVEST_DASHBOARD_STOCK_DETAIL = 'invest_dashboard_stock_detail',
  INVEST_DASHBOARD_FUND_DETAIL = 'invest_dashboard_fund_detail',

  INVEST_DASHBOARD_CONNECT = 'invest_dashboard_connect',
  ASSET_INVEST_DASHBOARD_CONNECT = 'asset_invest_dashboard_connect',
}

export const SCREEN_STATEMENT = {
  '/v2/dashboard': ScreenName.INVEST_DASHBOARD,
  '/v2/dashboard-sub-home/stock': ScreenName.INVEST_DASHBOARD_STOCK_DETAIL,
  '/v2/dashboard-sub-home/fund': ScreenName.INVEST_DASHBOARD_FUND_DETAIL,
};

export const sendTrackEvent = (
  eventName: TrackEventName,
  viewName: string,
  eventParam: TrackEventParam = {},
  debug = false
): void => {
  if (debug) {
    console.log(`${eventName}__${viewName} / ${JSON.stringify(eventParam)}`);
  }
  try {
    trackAnalyticsEvent({
      name: `${eventName}__${viewName}`,
      eventPropertiesJson: {
        ...eventParam,
      },
    });
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
  }
};

export const sendTrackEventFirebase = (
  eventName: TrackEventName,
  viewName: string,
  eventParam: TrackEventParam = {}
): void => {
  try {
    trackAnalyticsEvent({
      name: `${eventName}__${viewName}`,
      eventPropertiesJson: {
        ...eventParam,
      },
      eventPlatformEnum: [EventPlatform.EVENT_PLATFORM_FIREBASE],
    });
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
  }
};

export interface ClickBSCarePolicyItemParams {
  itemType: 'CONTENT' | 'AGREEMENT' | 'ALL_AGREEMENT';
  policyName?: string;
}

import {
  SpacingHeight20,
  SpacingHeight28,
  TitleChildLeftTextLarge1,
  TitleXXLarge1,
} from '@banksalad/bpl-web';
import { maxBy, sum } from 'lodash';

import { ChartData } from '@/entities/chart-data';
import { InvestmentRevenueEntity } from '@/entities/revenue';
import { ASSET_TYPES, getChartDataWithColor } from '@/utils/portfolio-data-format';
import {
  checkAssets,
  processInvestAssetData,
  refineInvestmentRevenue,
} from '@/views/dashboard-exp/controller';
import { EmptyPieChart, PieChart } from '@/views/dashboard-exp/pie-chart';
import {
  EmptyInvestmentSection,
  InvestmentAssets,
  InvestmentSection,
} from '@/views/dashboard-exp/portfolio-investment';

interface Props {
  investmentRevenueData: InvestmentRevenueEntity;
}

export const Portfolio = ({ investmentRevenueData }: Props) => {
  const {
    totalValuation,
    stockValuation,
    stockRevenue,
    stockRevenueRate,
    stockPrincipal,
    fundPrincipal,
    fundValuation,
    fundRevenue,
    fundRevenueRate,
    coinPrincipal,
    coinValuation,
    coinRevenue,
    coinRevenueRate,
  } = refineInvestmentRevenue(investmentRevenueData);

  const getChartData = (): ChartData[] => {
    const chartData: ChartData[] = [
      getChartDataWithColor(ASSET_TYPES.STOCK, stockValuation),
      getChartDataWithColor(ASSET_TYPES.FUND, fundValuation),
      getChartDataWithColor(ASSET_TYPES.COIN, coinValuation),
    ].filter(data => data.value >= 0);

    const { title: activeTitle } = maxBy(chartData, 'value')!;

    return chartData.map(asset =>
      asset.title === activeTitle ? { ...asset, active: true } : asset
    );
  };

  const { hasInvestmentAssets } = checkAssets([totalValuation]);

  const isEmptyInvestmentSection = !hasInvestmentAssets;

  const totalAmount = sum([stockValuation, fundValuation, coinValuation]);

  const getInvestAssets = (): InvestmentAssets => {
    const stock =
      stockPrincipal !== 0
        ? processInvestAssetData({
            type: ASSET_TYPES.STOCK,
            valuation: stockValuation,
            revenue: stockRevenue,
            revenueRate: stockRevenueRate,
            principal: stockPrincipal,
            totalAmount,
          })
        : undefined;

    const fund =
      fundPrincipal !== 0
        ? processInvestAssetData({
            type: ASSET_TYPES.FUND,
            valuation: fundValuation,
            revenue: fundRevenue,
            revenueRate: fundRevenueRate,
            principal: fundPrincipal,
            totalAmount,
          })
        : undefined;

    const coin =
      coinPrincipal !== 0
        ? processInvestAssetData({
            type: ASSET_TYPES.COIN,
            valuation: coinValuation,
            revenue: coinRevenue,
            revenueRate: coinRevenueRate,
            principal: coinPrincipal,
            totalAmount,
          })
        : undefined;

    const defaultAssets = { stock, coin };

    // 투자종목이 없는 경우
    if (!hasInvestmentAssets) {
      return defaultAssets;
    }

    return {
      ...defaultAssets,
      fund,
    };
  };

  return (
    <>
      <SpacingHeight20 />
      <TitleXXLarge1 style={{ padding: '8px 20px' }}>
        <TitleChildLeftTextLarge1 text="포트폴리오" fontWeight="bold" />
      </TitleXXLarge1>
      <SpacingHeight20 />
      {/* 원래는 SpacingHeight20 1개만 필요하지만 파이차트가 잘리는 이슈 때문에 SpacingHeight20 하나를 더 추가함  https://banksalad.slack.com/archives/C02DE30ANMC/p1638990672320000?thread_ts=1638937647.312800&cid=C02DE30ANMC */}
      <SpacingHeight20 />
      <PieChart chartData={getChartData()} />
      {isEmptyInvestmentSection ? (
        <EmptyInvestmentSection />
      ) : (
        <InvestmentSection totalValuation={totalValuation} assets={getInvestAssets()} />
      )}
    </>
  );
};

export const EmptyPortfolio = () => (
  <>
    <SpacingHeight20 />
    <TitleXXLarge1 style={{ padding: '8px 20px' }}>
      <TitleChildLeftTextLarge1 text="포트폴리오" fontWeight="bold" />
    </TitleXXLarge1>
    <SpacingHeight20 />
    <EmptyPieChart />
    <SpacingHeight28 />
  </>
);

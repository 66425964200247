import { WniAppInfo, WniAppInfoKeys } from './wni-app-info.type';

/**
 * session storage에서 key에 해당하는 app info를 가져옵니다.
 */
export const getWniAppInfo = <T extends WniAppInfoKeys>(key: T): WniAppInfo[T] | undefined => {
  const item = sessionStorage.getItem(key);

  if (item === null) {
    console.warn(`${key} does not exist in session storage`);
    return;
  }

  try {
    const parsedItem = JSON.parse(item);
    return parsedItem;
  } catch (e) {
    console.warn(
      `${key}의 값(${item})은 JSON.parse 될 수 없습니다. 문자열은 double quote로 감싸서 저장해야합니다. (ex. '"ios"')`
    );
  }
};

/* eslint-disable no-nested-ternary */
import { BdsMonoCommonLossXSmall1Svg, BdsMonoCommonProfitXSmall1Svg } from '@banksalad/bpl-web';
import { memoize } from 'lodash';

export const isProfit = memoize((revenue: number) => revenue > 0);

export const renderTernaryIconByRevenue = (revenue: number) => {
  if (revenue !== 0) {
    const profit = isProfit(revenue);
    return profit ? (
      <BdsMonoCommonProfitXSmall1Svg width={8} height={8} color={profit ? 'red-100' : 'blue-100'} />
    ) : (
      <BdsMonoCommonLossXSmall1Svg width={8} height={8} color={profit ? 'red-100' : 'blue-100'} />
    );
  }
  return undefined;
};

export const getTernaryColorByRevenue = (revenue: number) =>
  isProfit(revenue) ? 'red-100' : revenue === 0 ? 'gray-500' : 'blue-100';

import {
  CardChildLeftTextSmall1,
  CardChildRightIconTextSmall1,
  CardListCard1,
  DetailChildTopLine2Medium1,
  DetailTopNormal1,
} from '@banksalad/bpl-web';

import { RevenueDetailEntity } from '@/entities/revenue';
import { toCurrencyWithFloor } from '@/utils';
import { getProfitColor, getProfitIcon } from '@/views/dashboard-sub-home/controller';

interface Props {
  stockRevenue: RevenueDetailEntity;
}

export const DashboardSubHomeStockSummary = ({ stockRevenue }: Props) => {
  const { principal: principal_amount, revenue_amount, rate_2f } = stockRevenue;

  const principal = Math.round(+principal_amount.amount_2f / 100);
  const revenue = Math.round(+revenue_amount.amount_2f / 100);
  const revenueRate = rate_2f / 100;

  const profitColor = getProfitColor(revenue);
  const profitIcon = getProfitIcon(revenue);

  const listSet = [
    [
      {
        left: <CardChildLeftTextSmall1 text="투자원금" />,
        right: (
          <CardChildRightIconTextSmall1
            text={`${toCurrencyWithFloor(principal)}원`}
            color="gray-100"
          />
        ),
      },
      {
        left: <CardChildLeftTextSmall1 text="총 수익금(률)" />,
        right: (
          <CardChildRightIconTextSmall1
            text={`${toCurrencyWithFloor(revenue)}원(${revenueRate}%)`}
            color={profitColor}
            icon={profitIcon}
          />
        ),
      },
    ],
  ];

  return (
    <>
      <DetailTopNormal1 text="총 주식 평가금">
        <DetailChildTopLine2Medium1
          text={`${toCurrencyWithFloor(principal + revenue)}`}
          suffixText="원"
        />
      </DetailTopNormal1>
      <CardListCard1 isMoreButtonVisible={false} listSet={listSet} />
    </>
  );
};

import { DividerHeight12Margin0, SpacingHeight20, SpacingHeight24 } from '@banksalad/bpl-web';
import React, { useEffect } from 'react';

import { FetchError } from '@/components/fetch-error';
import { Loader } from '@/components/loader';
import { useFetchFundInvestments, useFetchInvestmentRevenues } from '@/hooks/fetch';
import { trackExposeViewSubHomeFund } from '@/trackers/dashboard-exp';
import { DashboardSubHomeFundList } from '@/views/dashboard-sub-home/fund/list';
import { DashboardSubHomeFundSummary } from '@/views/dashboard-sub-home/fund/summary';

export const DashboardSubHomeFund = () => {
  const {
    data: _investmentRevenueData,
    isLoading: revenueLoading,
    isError: revenueError,
  } = useFetchInvestmentRevenues();
  const { data: fundData, isLoading: fundLoading, isError: fundError } = useFetchFundInvestments();

  useEffect(() => {
    trackExposeViewSubHomeFund();
  }, []);

  if (revenueLoading || fundLoading) {
    return <Loader />;
  }
  if (revenueError || fundError) {
    return <FetchError onRetry={window.location.reload} />;
  }

  const investmentRevenueData = _investmentRevenueData!;
  const fundRevenue = investmentRevenueData.fund_revenue;
  const fundList = fundData.data;

  return (
    <>
      <DashboardSubHomeFundSummary fundRevenue={fundRevenue} />

      <SpacingHeight24 />
      <DividerHeight12Margin0 />
      <SpacingHeight20 />

      <DashboardSubHomeFundList fundList={fundList} />
    </>
  );
};

import { useQuery } from 'react-query';

import { getStockInvestments } from '@/api/investment';
import { StocksEntity } from '@/entities/investment/stock';

export const useFetchStockInvestments = () => {
  const {
    data: stocksData,
    error,
    isLoading,
    isError,
    ...rest
  } = useQuery('stock-investment', getStockInvestments);

  if (isError) {
    throw error;
  }

  if (isLoading) {
    return {
      ...rest,
      isLoading,
      isError,
      data: {} as StocksEntity,
    };
  }

  const filteredStockData = stocksData!.data.filter(
    d => !(d.hidden || d.closed) && d.deleted_status === 'normal'
  );

  return {
    ...rest,
    isLoading,
    isError,
    data: {
      ...stocksData,
      data: filteredStockData,
    } as StocksEntity,
  };
};

import { every } from 'lodash';

import { InvestmentRevenueEntity, RevenueDetailEntity } from '@/entities/revenue';
import { ASSET_TYPES } from '@/utils/portfolio-data-format';
import { AssetDetail } from '@/views/dashboard-exp/portfolio-investment';

export const checkAssets = ([investmentTotalValuation, ...accountBalances]: number[]) => {
  const hasInvestmentAssets = investmentTotalValuation > 0;
  const hasAccountAssets = !isAllZeroAmount(...accountBalances);

  return { hasInvestmentAssets, hasAccountAssets };
};

export const isAllZeroAmount = (...amounts: number[]) => every(amounts, amount => amount === 0);

const refineRevenueDetail = ({
  principal: principal_amount,
  revenue_amount,
  rate_2f,
}: RevenueDetailEntity) => {
  const principal = Math.round(+principal_amount.amount_2f / 100);
  const revenue = Math.round(+revenue_amount.amount_2f / 100);
  const revenueRate = rate_2f / 100;
  const valuation = principal + revenue;

  return { principal, revenue, revenueRate, valuation };
};

export const refineInvestmentRevenue = ({
  total_valuation,
  total_revenue,
  stock_revenue,
  fund_revenue,
  coin_revenue,
}: InvestmentRevenueEntity) => {
  const {
    principal,
    revenue: totalRevenue,
    revenueRate: totalRevenueRate,
  } = refineRevenueDetail(total_revenue);
  const totalValuation = Math.round(+total_valuation.amount_2f / 100);

  const {
    principal: stockPrincipal,
    revenue: stockRevenue,
    revenueRate: stockRevenueRate,
    valuation: stockValuation,
  } = refineRevenueDetail(stock_revenue);
  const {
    principal: fundPrincipal,
    revenue: fundRevenue,
    revenueRate: fundRevenueRate,
    valuation: fundValuation,
  } = refineRevenueDetail(fund_revenue);
  const {
    principal: coinPrincipal,
    revenue: coinRevenue,
    revenueRate: coinRevenueRate,
    valuation: coinValuation,
  } = refineRevenueDetail(coin_revenue);

  const stockFundRevenue = stockRevenue + fundRevenue;
  const stockFundRevenueRate = (
    (stockFundRevenue / (stockPrincipal + fundPrincipal)) *
    100
  ).toFixed(2);

  return {
    principal,
    totalRevenue,
    totalRevenueRate,
    totalValuation,

    stockPrincipal,
    stockRevenue,
    stockRevenueRate,
    stockValuation,

    fundPrincipal,
    fundRevenue,
    fundRevenueRate,
    fundValuation,

    stockFundRevenue,
    stockFundRevenueRate,

    coinPrincipal,
    coinRevenue,
    coinRevenueRate,
    coinValuation,
  };
};

interface ProcessInvestAssetDataArgs {
  type: ASSET_TYPES;
  valuation: number;
  revenue: number;
  revenueRate: number;
  totalAmount: number;
  principal: number;
}

export const processInvestAssetData = ({
  type,
  valuation,
  revenue,
  revenueRate,
  totalAmount,
  principal,
}: ProcessInvestAssetDataArgs): AssetDetail => ({
  type,
  percentage: (valuation / totalAmount) * 100,
  valuation,
  revenue,
  revenueRate,
  principal,
});

import { OptionsType } from 'cookies-next/lib/types';

import { CookieAppInfoKeys, getCookieAppInfo } from '../cookie-app-info';
import { AppInfoKeys } from '../shared.type';
import { getWniAppInfo, WniAppInfoKeys } from '../wni-app-info';

export const APP_INFO_KEY_TO_COOKIE_APP_INFO_KEY_MAP: Record<
  AppInfoKeys,
  Exclude<CookieAppInfoKeys, 'Authorization'>
> = {
  accessToken: 'authorization',
  apiEnv: 'banksalad-api-env',
  osName: 'banksalad-application-name',
  deviceId: 'banksalad-device-id',
  sessionId: 'banksalad-session-id',
  requestId: 'banksalad-request-id',
  osVersion: 'banksalad-os-version',
  applicationVersion: 'banksalad-application-version',
  buildType: 'banksalad-build-type',
  releaseType: 'banksalad-release-type',
} as const;

export const APP_INFO_KEY_TO_WNI_APP_INFO_KEY_MAP: Record<
  Exclude<AppInfoKeys, 'requestId'>,
  WniAppInfoKeys
> = {
  accessToken: 'accessToken',
  apiEnv: 'appType',
  buildType: 'appBuild',
  osName: 'osName',
  applicationVersion: 'appVersion',
  deviceId: 'deviceId',
  osVersion: 'osVersion',
  sessionId: 'sessionId',
  releaseType: 'appRelease',
} as const;

/**
 * 인자로 전달받은 키에 해당 하는 appInfo를 조회합니다.
 * severSide에서는 cookie 정보만 가지고 있으므로 cookie만을 조회하고,
 * clientSide에서는 cookie를 먼저 조회하고 없으면 session에 있는 wni appInfo 를 조회합니다.
 * requestId 는 cookie 정보에서만 조회되는 정보로 cookie에 없으면 빈문자열을 리턴합니다.
 */
export const getAppInfo = (key: AppInfoKeys, options?: OptionsType) => {
  const cookieAppInfoKey = APP_INFO_KEY_TO_COOKIE_APP_INFO_KEY_MAP[key];

  if (typeof window === 'undefined') {
    return getCookieAppInfo(cookieAppInfoKey, options) ?? '';
  }

  if (key === 'requestId') {
    return getCookieAppInfo(cookieAppInfoKey, options) ?? '';
  }

  const wniAppInfoKey = APP_INFO_KEY_TO_WNI_APP_INFO_KEY_MAP[key];

  return getCookieAppInfo(cookieAppInfoKey, options) ?? getWniAppInfo(wniAppInfoKey) ?? '';
};

import {
  APP_INFO_FROM_COOKIE_KEYS,
  APP_INFO_KEYS,
  createCsrAxiosInstance,
} from '@banksalad/js-banksalad';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { getAppInfo } from '@/utils/app-info';

const getRequestHeader = () =>
  ({
    Accept: 'application/json',
    Pragma: 'no-cache',
    Authorization: getAppInfo('accessToken'),
    [APP_INFO_FROM_COOKIE_KEYS.APPLICATION_NAME]: getAppInfo('osName'),
    [APP_INFO_FROM_COOKIE_KEYS.APP_VERSION]: getAppInfo(APP_INFO_KEYS.OS_VERSION),
  } as const);

const axiosGatewayInstance = () =>
  createCsrAxiosInstance(
    {
      namespaceEnv: window.namespaceEnv,
      isRequestToBaseURL: false,
      prodBaseURL: window.apiGatewayHost,
      devBaseURL: window.apiGatewayHost,
      timeout: 10000,
      headers: getRequestHeader(),
    },
    { camelizeResponse: false, snakifyRequest: false }
  );

const axiosInstance = () =>
  createCsrAxiosInstance(
    {
      namespaceEnv: window.namespaceEnv,
      isRequestToBaseURL: false,
      prodBaseURL: window.apiHost,
      devBaseURL: window.apiHost,
      timeout: 10000,
      headers: getRequestHeader(),
    },
    { camelizeResponse: false, snakifyRequest: false }
  );

// legacy api
export const requestGatewayGET = async <T>(
  path: string,
  options?: Pick<AxiosRequestConfig, 'params' | 'headers'>
): Promise<T> => {
  try {
    const { data }: AxiosResponse<T> = await axiosGatewayInstance().get(path, { ...options });
    return data;
  } catch (e) {
    return handleError(e as AxiosError);
  }
};

export const requestGET = async <T>(
  path: string,
  options?: Pick<AxiosRequestConfig, 'params' | 'headers'>
): Promise<T> => {
  try {
    const { data }: AxiosResponse<T> = await axiosInstance().get(path, { ...options });
    return data;
  } catch (e) {
    return handleError(e as AxiosError);
  }
};

export const requestGatewayPOST = async <T, K>(
  path: string,
  payload?: T,
  options?: AxiosRequestConfig
): Promise<K> => {
  try {
    const { data }: AxiosResponse<K> = await axiosGatewayInstance().post(path, payload, options);
    return data;
  } catch (e) {
    return handleError(e as AxiosError);
  }
};

export const requestPOST = async <T, K>(
  path: string,
  payload?: T,
  options?: AxiosRequestConfig
): Promise<K> => {
  try {
    const { data }: AxiosResponse<K> = await axiosInstance().post(path, payload, options);
    return data;
  } catch (e) {
    return handleError(e as AxiosError);
  }
};

const handleError = (e: AxiosError) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  throw e.response ? new NetworkError(e.response.status, (e.response.data as any)?.message) : e;
};

class NetworkError extends Error {
  public statusCode: number;

  constructor(statusCode: number, message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.statusCode = statusCode;
  }
}

import {
  ButtonContainerNormalFlexible,
  ButtonNormalSmall,
  EmptyViewNormal,
  ImageEmptyError,
} from '@banksalad/bpl-web';
import React, { CSSProperties, ReactElement } from 'react';

import s from './styles.pcss';

interface Props {
  styles?: CSSProperties;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRetry?: (params?: any) => any;
}

const DEFAULT_MARGIN_TOP = '200px';
const ERROR_IMAGE_HEIGHT = '261px';

export const FetchError = ({
  onRetry,
  styles = { minHeight: `calc(${DEFAULT_MARGIN_TOP} + ${ERROR_IMAGE_HEIGHT})` },
}: Props): ReactElement => (
  <div className={s.wrap} style={{ ...styles }}>
    <EmptyViewNormal
      text={'일시적 오류가 발생했습니다.\n다시 한번 시도해주세요.'}
      image={<ImageEmptyError />}
    >
      <ButtonContainerNormalFlexible>
        <ButtonNormalSmall
          onClick={onRetry}
          text="재시도"
          fontWeight="medium"
          backgroundOpacity={15}
          color="green-100"
          styleType="flexible"
        />
      </ButtonContainerNormalFlexible>
    </EmptyViewNormal>
  </div>
);

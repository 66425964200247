import { APP_INFO_KEYS, getAppInfoFromSessionStorage } from '@banksalad/js-banksalad';

declare global {
  interface Window {
    namespaceEnv: NAMESPACE_ENV;
    apiGatewayHost: string;
    apiHost: string;
    webViewFinish: () => void;
    msw: any;
  }
}

enum NAMESPACE_ENV {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
  STAGING = 'staging',
}

export const APPLICATION_NAME = {
  ANDROID: 'banksalad2:Android',
  IOS: 'banksalad2:iOS',
};

const applicationName = getAppInfoFromSessionStorage(APP_INFO_KEYS.APPLICATION_NAME);

const isAndroid = (): boolean => applicationName === APPLICATION_NAME.ANDROID;
const isIOS = (): boolean => applicationName === APPLICATION_NAME.IOS;
const isDev = (): boolean => window.namespaceEnv !== NAMESPACE_ENV.PRODUCTION;
const isProd = (): boolean => window.namespaceEnv === NAMESPACE_ENV.PRODUCTION;

export { isAndroid, isDev, isIOS, isProd, NAMESPACE_ENV };

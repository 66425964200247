/* eslint-disable no-unused-expressions */
import * as Sentry from '@sentry/browser';

import { CONFIG } from '@/utils/sentry/config';

const initSentry = () => {
  Sentry.init(CONFIG.SENTRY);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sentryErrorHandler = (func?: (error: any) => void) => (error: any) => {
  Sentry.setExtra('errorLog', error);
  Sentry.captureException(error);

  func && func(error);
};

export { initSentry, sentryErrorHandler };

import {
  DetailChildTopLine2Medium1,
  DetailDashboard1,
  DividerHeight1Margin0,
} from '@banksalad/bpl-web';

import { toCurrencyWithFloor } from '@/utils';

interface Props {
  totalValuation: number;
}
export const DashboardTop = ({ totalValuation }: Props) => (
  <>
    <DetailDashboard1
      text="투자 평가금"
      topChildren={
        <DetailChildTopLine2Medium1
          text={`${toCurrencyWithFloor(totalValuation)}`}
          suffixText="원"
        />
      }
    />
    <DividerHeight1Margin0 />
  </>
);

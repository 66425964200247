import {
  BdsMonoCommonArrowRightSmall1Svg,
  CardTextCenter1,
  ListItemChildLeftIconTextSmall3,
  ListItemChildRightLine2Medium5,
  ListItemXSmall1,
  SpacingHeight8,
  SpacingHeight20,
  TitleChildLeftTextSmall1,
  TitleChildRightTextSmall1,
  TitleSmall1,
} from '@banksalad/bpl-web';
import { useMemo } from 'react';

import { omitAfterDecimalPoint, toCurrencyWithFloor } from '@/utils';
import { getTernaryColorByRevenue, renderTernaryIconByRevenue } from '@/utils/icon-renderer';
import { linkTo } from '@/utils/link';
import {
  ASSET_COLOR,
  ASSET_TYPE_KOR,
  ASSET_TYPES,
  getIconByAssetType,
  PERCENTAGE_DECIMAL_POSITION,
} from '@/utils/portfolio-data-format';

export interface AssetDetail {
  type: ASSET_TYPES;
  percentage: number;
  valuation: number;
  revenue: number;
  revenueRate: number;
  principal: number;
}

export interface InvestmentAssets {
  stock?: AssetDetail;
  fund?: AssetDetail;
  p2p?: AssetDetail;
  coin?: AssetDetail;
}

interface InvestmentSectionProps {
  totalValuation: number;
  assets: InvestmentAssets;
}

interface InvestmentSectionItemProps {
  asset: AssetDetail;
}

const InvestmentSectionItem = ({
  asset: { type, principal, percentage, valuation, revenue, revenueRate },
}: InvestmentSectionItemProps) => {
  const subtext = `${toCurrencyWithFloor(revenue)}원 (${revenueRate}%)`;

  const handleClick = () => {
    if (type === ASSET_TYPES.COIN) {
      return;
    }
    linkTo.dashboardSubHome(type);
  };

  const renderSectionItemRightIcon = (type: ASSET_TYPES) => {
    if (type === ASSET_TYPES.COIN) {
      return <BdsMonoCommonArrowRightSmall1Svg width={8} height={12} color="white-900" />;
    }
    return <BdsMonoCommonArrowRightSmall1Svg width={8} height={12} color="gray-550" />;
  };

  const AssetTypeIcon = useMemo(() => getIconByAssetType(type), [type]);

  return (
    <ListItemXSmall1 onClick={handleClick}>
      <ListItemChildLeftIconTextSmall3
        text={ASSET_TYPE_KOR[type]}
        subtext={`${omitAfterDecimalPoint(percentage, PERCENTAGE_DECIMAL_POSITION)}%`}
        icon={<AssetTypeIcon width={24} height={24} />}
        iconBackgroundColor={ASSET_COLOR[type]}
        iconBackgroundOpacity={15}
      />
      {principal < 0 ? (
        <ListItemChildRightLine2Medium5
          text="잘못된 금액이 존재합니다"
          color="gray-500"
          subtext=""
          subtextIcon=""
          rightIcon={renderSectionItemRightIcon(type)}
        />
      ) : (
        <ListItemChildRightLine2Medium5
          text={`${toCurrencyWithFloor(valuation)}원`}
          subtext={subtext}
          subtextColor={getTernaryColorByRevenue(revenue)}
          subtextIcon={renderTernaryIconByRevenue(revenue)}
          rightIcon={renderSectionItemRightIcon(type)}
        />
      )}
    </ListItemXSmall1>
  );
};

export const InvestmentSection = ({
  totalValuation,
  assets: { stock, fund, p2p, coin },
}: InvestmentSectionProps) => (
  <>
    <TitleSmall1>
      <TitleChildLeftTextSmall1 text="투자 평가금" />
      <TitleChildRightTextSmall1 text={`${toCurrencyWithFloor(totalValuation)}원`} />
    </TitleSmall1>

    {stock && <InvestmentSectionItem asset={stock} />}
    {fund && (
      <>
        <SpacingHeight8 />
        <InvestmentSectionItem asset={fund} />
      </>
    )}
    {p2p && (
      <>
        <SpacingHeight8 />
        <InvestmentSectionItem asset={p2p} />
      </>
    )}
    {coin && (
      <>
        <SpacingHeight8 />
        <InvestmentSectionItem asset={coin} />
      </>
    )}
    <SpacingHeight20 />
  </>
);

export const EmptyInvestmentSection = () => (
  <>
    <TitleSmall1>
      <TitleChildLeftTextSmall1 text="투자 평가금" />
      <TitleChildRightTextSmall1 text="0원" />
    </TitleSmall1>
    <SpacingHeight8 />
    <CardTextCenter1
      text={
        <span className="text-body-4 text-gray-500">
          투자중인 증권사 연결하고
          <br />
          나만의 금융 포트폴리오를 완성해보세요
        </span>
      }
      backgroundColor="gray-850"
    />
    <SpacingHeight20 />
  </>
);

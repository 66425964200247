import { CookieValueTypes, getCookie } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';
import { isString, pipe } from 'remeda';

import { getOsName } from '../get-os-name';
import { CookieAppInfoKeys } from './cookie-app-info.type';

/**
 * cookies-next lib에서 getCookie의 value가 boolean 일 가능성으로 인해서 boolean 이
 * 리턴타입의 union에 포함되어 있으나, appInfo cookie중 boolean값은 없으므로 boolean 을
 * 리턴타입에서 제외합니다.
 */

type AppInfoCookieValue = Exclude<CookieValueTypes, boolean>;

const getCookieExtended = (key: string, options?: OptionsType | undefined): AppInfoCookieValue =>
  getCookie(key, options) as AppInfoCookieValue;

/**
 *  cookie에서 key에 해당하는 appInfo를 가져옵니다.
 *
 * - IOS의 경우 최초 개발시 PascalCase를 사용했기 때문에 Authorization fallback
 * 이 필요
 * - Android의 경우 Authorization을 쓰면 만료된 token 정보가 들어올 수 있음
 * - authorization ?? Authorization 순으로 조회순서 강제필요
 *  관련 링크: https://banksalad.slack.com/archives/C045MMQUF9S/p1679383452927879?thread_ts=1679291903.002409&cid=C045MMQUF9S
 * */
export const getCookieAppInfo = (
  key: Exclude<CookieAppInfoKeys, 'Authorization'>,
  options?: OptionsType
): AppInfoCookieValue => {
  if (key === 'authorization') {
    return getCookieExtended(key, options) ?? getCookieExtended('Authorization', options);
  }
  if (key === 'banksalad-application-name') {
    return pipe(getCookieExtended(key, options), applicationName =>
      isString(applicationName) ? getOsName(applicationName) : undefined
    );
  }
  return getCookieExtended(key, options);
};
